<template>
    <div class="container h-100">
        <div class="row h-100">
            <div class="col-12 mt-5 pt-5 d-flex justify-content-center h-100">
                <div v-if="resetSuccess">
                    <h1 class="font-primary success-heading text-center mb-5 mt-5 pt-5">{{$t('SUCCESS')}}</h1>
                    <p class="auth-explanation-text">{{$t('NEW_PASSWORD_SET')}} <router-link style="text-decoration:underline" to='/login'>{{$t('SIGN_IN')}}</router-link>.</p>
                </div>
                <div v-else class="new-pass-wrapper h-100">
                    <h1 class="font-primary success-heading text-center mb-5 mt-5 pt-5">Email confirmed successfully!</h1>
                    
                    <div class="errors-wrapper my-4">
                        <p class="mb-0" :key="error" v-for="error of errorMessages">{{error}}</p>
                    </div>
                    
                    <form action="/" class="new-password-form">
                        <div class="form-group mb-0">
                            <label for="email">{{$t('EMAIL')}}</label>
                            <input readonly :value="email" type="email">
                        </div>

                        <div class="form-group position-relative mb-0">
                            <label for="password">{{$t('NEW_PASSWORD')}}</label>
                            <input class="pe-5" @input="passwordError = null" v-model="newPassword" id="password" :placeholder="$t('NEW_PASSWORD')" type="password">
                            <span v-if="passwordError" class="validation-error">{{passwordError}}</span>
                            <div tabindex="-1" class="info-wrapper">
                                <div class="info-icon">
                                    i
                                </div>
                                <div class="info-box">
                                    <p class="mb-0 pe-0">{{$t('PASSWORD_REQUIREMENTS')}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="form-group mb-0">
                            <label for="confirm-password">{{$t('CONFIRM_NEW_PASSWORD')}}</label>
                            <input @input="confirmPasswordError = null" v-model="confirmNewPassword" id="confirm-password" :placeholder="$t('CONFIRM_NEW_PASSWORD')" type="password">
                            <span v-if="confirmPasswordError" class="validation-error">{{confirmPasswordError}}</span>
                        </div>

                        <button :disabled="disableSubmit" @click="submitNewPassword" type="button" class="button mt-5 w-100">{{$t('SUBMIT')}}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import config from '../../config';

export default defineComponent({
    name: 'New Password',
    data: function() {
        return {
            newPassword: '',
            confirmNewPassword: '',
            confirmationToken: null,
            email: '',
            token: '',
            resetSuccess: false,
            passwordError: null,
            confirmPasswordError: null,
            disableSubmit: false,
            errorMessages: []
        }
    },
    mounted() {
        const QueryString = window.location.search;
        const urlParams = new URLSearchParams(QueryString);
        this.email = urlParams.get('email').replace(' ', '+');
        this.token = urlParams.get('token');
    },
    methods: {
        submitNewPassword() {
            if (!this.newPassword.length) {
                this.passwordError = this.$t('PLEASE_ENTER_NEW_PASSWORD')
            } else if (!this.newPassword.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/gi)) {
                this.passwordError = this.$t('PASSWORD_DOES_NOT_MEET_REQUIREMENTS');
            } else {
                this.passwordError = null;
            }

            if (!this.confirmNewPassword.length) {
                this.confirmPasswordError = this.$t('PLEASE_ENTER_NEW_PASSWORD');
            } else if (this.confirmNewPassword !== this.newPassword) {
                this.confirmPasswordError = this.t$('PASSWORD_CONFIRM_NO_MATCH');
            } else {
                this.confirmPasswordError = null;
            }

            this.disableSubmit = true;

            console.log(this.token);

            fetch(`${config.API}/api/v1/reset-password`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: this.email,
                    token: this.token,
                    password: this.newPassword,
                    password_confirmation: this.confirmNewPassword
                })
            })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                this.disableSubmit = false;
                if (data.data.access_token) {
                    localStorage.setItem('accessToken', data.data.access_token);
                    this.$toast.success(this.$t('PASSWORD_CHANGE_SUCCESS'));
                    setTimeout(() => {
                        this.$router.push('/login');
                    }, 2000);
                } else if (data.data.errors) {
                    this.errorMessages = data.data.errors;
                }
            })
            .catch(error => {
                console.log(error);
                this.disableSubmit = false;
            })
        }
    }
});
</script>
